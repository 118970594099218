import { useQuery } from 'react-query';
import UserContextService from '../services/UserContextService';
import { QUERY_KEYS } from '../constants/queryKeys';
import { useMsal } from '@azure/msal-react';

const useUserContextData = () => {
  const { instance } = useMsal();
  const isLoggedIn = instance.getAllAccounts().length > 0;
  return useQuery({
    queryKey: QUERY_KEYS.getUserContextToken,
    queryFn: async () => {
      return await UserContextService.getUserContextToken();
    },
    enabled: isLoggedIn,
  });
};

export default useUserContextData;
