import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { MutableRefObject } from "react";

/**
 * Export the graph dashboard into the pdf format.
 * 
 * @param reportTemplateRef reference of the graph dashboard.
 */
export const handleExportGraphPDF = async (reportTemplateRef: MutableRefObject<null>) => {
  if (reportTemplateRef.current) {
    const canvas = await html2canvas(reportTemplateRef.current, {
      scale: 2,
    });
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF({
      orientation: 'landscape',
      unit: 'pt',
      format: [canvas.width, canvas.height]
    });
    pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
    pdf.save('graph.pdf');
  }
};

/**
 * Export the table data into the csv file format.
 * 
 * @param reportTemplateRef reference of the table
 */
export const handleExportTablePDF = async (reportTemplateRef: any) => {
  reportTemplateRef?.current?.exportCSV();
};