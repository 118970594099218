import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import axios, { AxiosHeaders, AxiosInstance } from 'axios';
import { msalConfig } from '../auth/authConfig';
import ConfigService from './ConfigService';
import { getScopesBasedOnService, getSessionStorageItem} from '../utils/utils';
import { STORAGE_KEYS } from '../constants/storageKeys';
import { ServiceName } from '../enums/ServiceName';

const { UserContextToken } = STORAGE_KEYS;

interface TokenRequest {
  scopes: any;
  account: any;
}

const getAxiosDefaultCreateObj = (baseVersionUrl: string, apiVersion = 'v0.1/') => {
  return {
    baseURL: `${ConfigService.getEnvValue(baseVersionUrl)}${apiVersion}`,
    headers: {
      'Content-type': 'application/json',
    },
  };
};

export const apiClient: AxiosInstance = axios.create(getAxiosDefaultCreateObj('REACT_APP_REPORT_URL'));

export const apiClientAppBff: AxiosInstance = axios.create(getAxiosDefaultCreateObj('REACT_APP_BFF_URL'));

export const apiClientV2: AxiosInstance = axios.create(
  getAxiosDefaultCreateObj('REACT_APP_BFF_URL', 'v0.2/')
);

const clientConfig = (config: any, serviceName='') => {
  const accessTokenRequest: TokenRequest = {} as TokenRequest;

  const authConfig = ConfigService.getConfig();
  return authConfig.then((data) => {
    msalConfig.auth.clientId = data.auth.clientId;
    msalConfig.auth.authority = data.auth.authority;
    accessTokenRequest.scopes = getScopesBasedOnService(data.auth.scopes, serviceName);

    const msalInstance = new PublicClientApplication(msalConfig);
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length === 0) return config;

    accessTokenRequest.account = accounts[0];

    return msalInstance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse: any) => {
        const accessToken = accessTokenResponse.accessToken;

        if (config.headers) {
          const headers = config.headers as AxiosHeaders;
          headers.set('Authorization', `Bearer ${accessToken}`);
          headers.set('custom-authorization', getSessionStorageItem(UserContextToken));
        }

        return Promise.resolve(config);
      })
      .catch(async (e) => {
        if (e instanceof InteractionRequiredAuthError) {
          await msalInstance.acquireTokenRedirect(accessTokenRequest);
        }

        throw e;
      });
  });
};

const errorHandling = (error: any) => {
  return Promise.reject(new Error(error));
};

apiClient.interceptors.request.use(
  function (config) {
    return clientConfig(config, ServiceName.SM_SERVICE);
  },
  function (error) {
    return errorHandling(error);
  }
);

apiClientAppBff.interceptors.request.use(
  function (config) {
    return clientConfig(config, ServiceName.BFF_SERVICE);
  },
  function (error) {
    return errorHandling(error);
  }
);

apiClientV2.interceptors.request.use(
  function (config) {
    return clientConfig(config);
  },
  function (error) {
    return errorHandling(error);
  }
);
