import { MessagesMessage } from 'primereact/messages';
import { Toast } from 'primereact/toast';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import '../../styles/components/Messages.scss';

export interface IGlobalMessage {
  messageSeverity: MessagesMessage['severity'];
  messageSummary: string;
  messageDetail: string | JSX.Element;
  isSticky?: boolean;
  messageVisisbilityInMilliSecs?: number;
}

interface IIconList {
  success: JSX.Element;
  warn: JSX.Element;
  info: JSX.Element;
  error: JSX.Element;
}

export type GlobalMessageHandle = {
  showGlobalMessage: (props: IGlobalMessage) => void;
};

const GlobalMessage = forwardRef<GlobalMessageHandle>((props, ref) => {
  const toast = useRef<Toast>(null);

  const toastIcon = (iconName: string): JSX.Element => (
    <div className='flex align-items-center'>
      <i className={`pi ${iconName}`} />
    </div>
  );

  const iconList: IIconList = {
    success: toastIcon('pi-check-circle'),
    info: toastIcon('pi-info-circle'),
    warn: toastIcon('pi-exclamation-triangle'),
    error: toastIcon('pi-times-circle'),
  };

  useImperativeHandle(ref, () => ({
    showGlobalMessage({
      messageSeverity,
      messageSummary,
      messageDetail,
      isSticky = false,
      messageVisisbilityInMilliSecs = 5000,
    }: IGlobalMessage) {
      toast?.current?.show({
        life: messageVisisbilityInMilliSecs,
        sticky: isSticky,
        severity: messageSeverity,
        summary: messageSummary,
        detail: messageDetail,
        closable: true,
        icon: messageSeverity && iconList[messageSeverity],
      });
    },
  }));

  return (
    <div className='card flex justify-content-center'>
      <Toast ref={toast} className={`p-toast`} position='top-left' />
    </div>
  );
});

GlobalMessage.displayName = 'GlobalMessage';
export default GlobalMessage;
