import React, { createContext, useEffect, useMemo, useState } from 'react';
import { User } from '../models/User';
import { useAppContext } from './AppContext';
import useUserAssignments from '../hooks/useUserAssignments';

interface ProviderProps {
  children: React.ReactNode;
}

export interface UserProfileContextType {
  user?: User;
  availableStations?: number[];
}

/** Context implementation */
export const UserProfileContext = createContext<UserProfileContextType>({});

export const UserProfileProvider = ({ children }: ProviderProps) => {
  const [user, setUser] = useState<User>();
  const { userContextToken } = useAppContext();
  const { data } = useUserAssignments(!!userContextToken);

  useEffect(() => {
    if (data != null) {
      setUser(data);
    }
  }, [data]);

  const context = useMemo<UserProfileContextType>(
    () => ({ user }),
    [user]
  );

  return <UserProfileContext.Provider value={context}>{children}</UserProfileContext.Provider>;
};


