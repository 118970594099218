export const Messages = {
  VOLUME_DISTRIBUTION_EXCEEDS: 'Please make sure total volume distribution should be 100% for ',
  DRUGS_NOT_AVAILABLE_FOR_SELECTED_CATEGORY: 'No drugs available for the selected category',
  DRUGS_NOT_AVAILABLE: 'No drug(s)',
  DATA_IS_NOT_AVAILABLE_FOR_SELECTED_DRUGS : 'Data is not available for the selected drugs, Please select other drugs',
  SOME_DRUGS_DATA_IS_NOT_AVAILABLE_FOR_SELECTED_DRUGS: 'A selected few drugs data not available',
  CANCEL_CONFIRMATION: 'Are you sure you want to leave?',
  CANCEL_CONFIRMATION_BODY:
    'If you leave, your changes will not be saved and you will have to start over.',
  LOGOUT_CONFIRM_HEADER: 'Are you sure you want to log out?',
  SOMETHING_WENT_WRONG: 'Oops! Something went wrong.',
};
