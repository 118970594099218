import './ScenarioOverviewTable.scss';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { ScenarioOverviewFinalData, ScenarioOverviewTableData } from '../../pages/scenario-overview/ScenarioOverview';
import { FinancialDrug } from '../../pages/home/Home';
import { useEffect, useState } from 'react';

export interface Scenario {
  heading: string,
  value: FinancialDrug[]
}

export interface IScenarioOverviewTable {
  data: ScenarioOverviewFinalData[][];
  drugs: Scenario;
  tableRef?: any
}

const ScenarioOverviewTable = ({ data, drugs, tableRef }: IScenarioOverviewTable) => {
  const tableHeading = ["EU Volume by product", "Reimbursement per EU", "Net Cost Recovery per EU", "Quarterly Reimbursements", "Quarterly Net cost recovery", "Rebate Estimate"];
  const [tableData, setTableData] = useState<ScenarioOverviewTableData[]>([]);

  useEffect(() => {
    const newData: ScenarioOverviewTableData[] = [];
    data?.forEach((scenario: ScenarioOverviewFinalData[], index: number) => {
      scenario?.forEach((item: ScenarioOverviewFinalData) => {
        newData.push({
          ...item,
          REPORT_TYPE: tableHeading[index],
          SCENARIO: item?.heading
        });
      });
    });
    setTableData(newData);
  }, [data]);

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column colSpan={2} />
        {
          drugs?.value?.map((drug: FinancialDrug) => (
            <Column className='text-sm' key={drug?.drugName} header={drug?.drugName} />
          ))
        }
      </Row>
    </ColumnGroup>
  )

  const scenarioCellTemplate = (value: any) => (
    <div id={value?.heading} className='text-sm font-semibold'>
      {value?.heading}
    </div>
  );

  const rowClass = (data: any) => {
    return {
      'bg-white' : data?.heading === 'Current',
      'surface-300' : data?.heading?.split(' ')[1] % 2 === 0,
      'bg-blue-100' : data?.heading?.split(' ')[1] % 2 !== 0
    };
  };

  const drugCellTemplate = (value: any, drug: FinancialDrug) => (
    <div data-testid='dispensed-drug-price' className={'text-sm'}>
      {value[drug?.drugName] ? value[drug?.drugName].toLocaleString() : 0}
    </div>
  );

  return (
    <div data-testid='scenario-overview-table' className="scenario-overview-table-card">
      <DataTable ref={tableRef} rowClassName={rowClass} value={tableData} headerColumnGroup={headerGroup} rowGroupMode="rowspan" groupRowsBy="REPORT_TYPE" showGridlines>
        <Column field="REPORT_TYPE" className='text-base font-bold current-cell' />
        <Column field='SCENARIO' body={scenarioCellTemplate} />
        {
          drugs?.value?.map((drug: FinancialDrug) => (
            <Column className={`text-sm`} key={drug?.drugName} field={drug?.drugName} body={(value) => drugCellTemplate(value, drug)} header={drug?.drugName} />
          ))
        }
      </DataTable>
    </div>
  )
}

export default ScenarioOverviewTable