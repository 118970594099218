import { SiteDetail } from './SiteDetail';
import { SpecialtyType } from './SpecialtyType';

export class Site {
  public id?: number;
  public isShippingSite?: boolean;
  public specialtyType?: SpecialtyType;
  public siteDetail?: SiteDetail;

  constructor(
    id?: number,
    isShippingSite?: boolean,
    specialtyType?: SpecialtyType,
    siteDetail?: SiteDetail
  ) {
    this.id = id;
    this.isShippingSite = isShippingSite;
    this.specialtyType = specialtyType;
    this.siteDetail = siteDetail;
  }
}
