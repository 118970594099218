export const ButtonLabels = {  
  DEFINE_SCENARIOS : 'DEFINE SCENARIOS',
  ADD_SCENARIO : 'ADD SCENARIO',
  GENERATE_SCENARIO : 'GENERATE SCENARIO',
  MODIFY_DRUGS : 'MODIFY DRUGS',
  RESET: 'RESET',
  EDIT_SCENARIOS: 'EDIT SCENARIOS',
  EXPORT: 'EXPORT',
  YES_IAM_SURE: 'Yes, I’m sure',
  NO_CONTINUE: 'No, Continue',
};
