import { STORAGE_KEYS } from '../constants/storageKeys';
import { setSessionStorageItem } from '../utils/utils';
import {apiClientAppBff } from './ApiClient';

const getUserContextToken = async () => {
  try {
    const { data } = await apiClientAppBff.get('/auth/custom-token',);
    setSessionStorageItem(STORAGE_KEYS.UserContextToken, data);
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const UserContextService = {
  getUserContextToken,
};

export default UserContextService;
