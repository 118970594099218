import { useQuery } from "react-query"
import CategoryAndDrugsService from "../services/CategoryAndDrugsService"

export const useGetAllCategoriesList = (id: number | undefined) => {
  return useQuery({
    queryKey: 'Categories',
    queryFn: async () => {return await CategoryAndDrugsService.getScenarioModelCategories(id)},
    refetchOnWindowFocus: false,
    enabled: !!id,
    retry: 0
  });
};