import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Image } from "primereact/image";
import { InputNumber } from "primereact/inputnumber";
import { Slider } from "primereact/slider";
import lock from '../../assets/images/lock.svg';
import './DefinitionTable.scss';
import 'primeicons/primeicons.css';
import { Dispatch, SetStateAction } from "react";
import { calculateTheSumBasedOnKey } from "../../utils/utils";
import { ScenarioHeading } from "../../enums/ScenarioHeading";
import { ProductFamily, Scenario } from "../../pages/home/Home";

export interface IDefinitionTable {
  index: number,
  scenarios: Scenario[],
  setScenario: Dispatch<SetStateAction<Scenario[]>>,
}

const DefinitionTable = ({
  index,
  scenarios,
  setScenario
}: IDefinitionTable) => {

  const handleLock = (drug: ProductFamily) => {
    const newDrugs = scenarios[index]?.value?.map((item: ProductFamily) =>
      item.productFamilyName === drug.productFamilyName
        ? { ...item, isLocked: !item.isLocked }
        : item
    );
    const newScenario = { ...scenarios[index], value: newDrugs };
    setScenario((prevScenarios: Scenario[]) =>
      prevScenarios.map((scenario, i) => i === index ? newScenario : scenario)
    );
  };

  const removeScenarioAndChangeHeadingName = (scenarios: Scenario[], value: number) => {
    return scenarios?.filter((scenario: Scenario, i: number) => i !== value).map((scenario: Scenario, i: number) => (
      i === 0 ? {
        ...scenario,
        heading: ScenarioHeading.CURRENT
      } : {
        ...scenario,
        heading: `${ScenarioHeading.SCENARIO} ${i}`
      }
    ));
  }

  const handleRemoveScenario = (value: number) => {
    setScenario((prev: Scenario[]) => removeScenarioAndChangeHeadingName(prev, value));
  }

  const handleSlideValue = (drug: ProductFamily, value: number | null | [number, number]) => {
    const newDrugs = scenarios[index]?.value?.map((item: ProductFamily) => {
      return item?.productFamilyName === drug?.productFamilyName ? { ...item, loggedInUserDistribution: value } : item;
    });
    const newScenario = { ...scenarios[index], value: newDrugs };
    setScenario((prevScenarios: Scenario[]) =>
      prevScenarios.map((scenario, i) => i === index ? newScenario : scenario)
    );
  }

  const volumeDistributionTemplate = (value: any) => {
    return (
      <div className="flex align-items-center gap-3 p-fluid">
        <InputNumber data-testid='input-volume' style={{ width: '16%' }} disabled={value?.isLocked} value={value?.loggedInUserDistribution} onChange={(e) => handleSlideValue(value, e.value)} min={0} max={100} suffix=" %" />
        <Slider data-testid='input-slider' className="w-9" disabled={value?.isLocked} value={value?.loggedInUserDistribution} onChange={(e) => handleSlideValue(value, e.value)} />
      </div>
    )
  }

  const lockTemplate = (value: ProductFamily) => {
    return (
      <div className={`flex align-items-center justify-content-center ${value?.isLocked ? 'lock' : ''}`}>
        <button type="button" data-testid='lock-volume-distribution' className="border-none button-background cursor-pointer" onClick={() => index !== 0 && handleLock(value)}>
          <Image className="" width="18" src={lock} alt="lock" />
        </button>
      </div>
    )
  }

  const drugTemplate = (value: ProductFamily) => {
    return (
      <div className="text-xs font-semibold">
        {value?.productFamilyName}
      </div>
    )
  }

  const scenarioHeaderTemplate = () => {
    return (
      <div className="flex align-items-center justify-content-between" >
        <div>
          {scenarios[index].heading}
        </div>
        {
          index > 0 && <button type="button" data-testid='remove-scenario' className="cursor-pointer border-none button-background" onClick={() => handleRemoveScenario(index)}><i className="pi pi-minus-circle" style={{ color: 'red' }}></i></button>
        }
      </div>
    )
  }

  const volumeDistributionHeaderTemplate = (value: Scenario) => {
    return (
      <div className="flex align-items-center justify-content-between">
        <div>
          {"Volume Distribution"}
        </div>
        {
          index !== 0 && <div data-testid='total-volume-distribution' className="bg-white border-500 p-1 text-xs border-round-sm border-1">{calculateTheSumBasedOnKey(value?.value, 'loggedInUserDistribution') + ' %'}</div>
        }
      </div>
    )
  }

  return (
    <DataTable data-testid='scenario-definition-data-table' value={scenarios[index]?.value} showGridlines>
      <Column className="w-5" header={scenarioHeaderTemplate} body={drugTemplate} />
      <Column className="w-5" header={() => volumeDistributionHeaderTemplate(scenarios[index])} body={volumeDistributionTemplate} />
      <Column className="cursor-pointer" header='Lock Volume' body={lockTemplate} />
    </DataTable>
  );
};

export default DefinitionTable;