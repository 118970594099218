import { Outlet } from 'react-router-dom';
import Sidebar from '../components/global/Sidebar';
import Toolbar from '../components/global/Toolbar';
import '../styles/pages/SharedLayout.scss';

const SharedLayout: React.FC = () => {
  return (
    <>
      <Toolbar />
      <Sidebar />
      <Outlet />
    </>
  );
};
export default SharedLayout;
