import { Category } from "../pages/home/Home";
import { apiClient } from "./ApiClient"

const getScenarioModelCategories = async(id: number | undefined) => {
  try {
    const {data} : {data: Category[]} =  await apiClient.get(`scenario-model/eligiblecategories?PracticeId=${id}`, {
      transformRequest: [
        (data, headers) => {
          if (headers['custom-authorization']) {
            delete headers['custom-authorization'];
          }
          return data;
        },
      ],
    });
    return data;
  } catch (error) {
    console.log('categoriesError', error);
    throw error;
  }
};

const CategoryAndDrugsService = {
  getScenarioModelCategories,
};

export default CategoryAndDrugsService;