import { Dispatch, SetStateAction } from 'react';
import { Modal } from './Modal';
import { PrimaryButton } from './PrimaryButton';
import { SecondaryButton } from './SecondaryButton';

export interface IConfirmationModalProps {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  primaryButtonLabel: string;
  primaryButtonOnClick: () => void;
  secondaryButtonLabel?: string;
  secondaryButtonOnClick?: () => void;
  headerText: string;
  bodyText: string;
  isIcon?: boolean;
  icon?: string;
  className?: string;
  bodyContent?: any;
  bodyTextClassName?: string;
}

const ConfirmationModal = ({
  isVisible,
  setIsVisible,
  primaryButtonLabel,
  primaryButtonOnClick,
  secondaryButtonLabel,
  secondaryButtonOnClick,
  headerText,
  bodyText,
  isIcon = true,
  icon = 'pi pi-exclamation-triangle',
  className,
  bodyContent,
  bodyTextClassName,
}: IConfirmationModalProps) => {
  const footer = () => {
    return (
      <div className='flex justify-content-end align-items-center'>
        {secondaryButtonOnClick && (
          <SecondaryButton
            className='uppercase'
            label={secondaryButtonLabel}
            onClick={secondaryButtonOnClick}
          />
        )}
        <PrimaryButton
          className='ml-2 mr-0 uppercase'
          label={primaryButtonLabel}
          onClick={primaryButtonOnClick}
        />
      </div>
    );
  };

  return (
    <Modal
      visible={isVisible}
      setVisible={setIsVisible}
      headerText={headerText}
      bodyText={bodyText}
      icon={isIcon ? icon : ''}
      footer={footer}
      data-testid='confirmation modal'
      bodyContent={bodyContent && !bodyText ? bodyContent : <></>}
      className={className}
      bodyTextClassName={bodyTextClassName}
    />
  );
};

export default ConfirmationModal;
