import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Blocker, useBlocker } from 'react-router-dom';
import { useAppContext } from './AppContext';
import { defaultGlobalConfirmationModalValues } from '../components/global/GlobalConfirmationModal';

interface AppRouteContextProps {
  children: React.ReactNode;
}

export interface IGlobalRouteRestrictionDetails {
  isRoutingBlocked: boolean;
  isConfirmationModalOpen: boolean;
  secondaryButtonLabel: string;
  primaryButtonLabel: string;
  secondaryButtonOnClick: () => void;
  primaryButtonOnClick: () => void;
  headerText: string;
  bodyText: string;
}

export interface IAppRouteContextType {
  routeRestrictionDetails: IGlobalRouteRestrictionDetails;
  setRouteRestrictionDetails: React.Dispatch<React.SetStateAction<IGlobalRouteRestrictionDetails>>;
}

export const AppRouteContext = createContext<IAppRouteContextType | undefined>(undefined);

const useAppRouteContext = () => {
  const context = useContext(AppRouteContext);
  if (!context) {
    throw new Error('useAppRouteContext must be used within a AppRouteContextProvider');
  }
  return context;
};

const defaultRouteRestrictionValues = {
  ...defaultGlobalConfirmationModalValues,
  isRoutingBlocked: false,
  isConfirmationModalOpen: false,
};

const AppRouteContextProvider = ({ children }: AppRouteContextProps) => {
  const [routeRestrictionDetails, setRouteRestrictionDetails] =
    useState<IGlobalRouteRestrictionDetails>(defaultRouteRestrictionValues);

  const { showGlobalConfirmationModal } = useAppContext();

  const blocker: Blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      routeRestrictionDetails.isRoutingBlocked && currentLocation.pathname !== nextLocation.pathname
  );

  const closeModal = () => {
    showGlobalConfirmationModal({
      ...routeRestrictionDetails,
      isVisible: false,
      setIsVisible: routeRestrictionDetails.primaryButtonOnClick,
    });
  };

  const proceedNavigation = (childrenFunction: () => void = () => null) => {
    return () => {
      childrenFunction();
      if (routeRestrictionDetails.isConfirmationModalOpen) {
        setRouteRestrictionDetails({ ...routeRestrictionDetails, isConfirmationModalOpen: false });
      } else {
        blocker.proceed?.();
        setRouteRestrictionDetails(defaultRouteRestrictionValues);
      }
      closeModal();
    };
  };

  const proceedRest = (childrenFunction: () => void = () => null) => {
    return () => {
      childrenFunction();
      closeModal();
      setRouteRestrictionDetails((prevValues) => ({
        ...prevValues,
        isConfirmationModalOpen: false,
      }));
      blocker.reset?.();
    };
  };

  useEffect(() => {
    if (blocker.state === 'blocked' || routeRestrictionDetails.isConfirmationModalOpen) {
      showGlobalConfirmationModal({
        ...routeRestrictionDetails,
        isVisible: true,
        setIsVisible: proceedRest(routeRestrictionDetails.primaryButtonOnClick),
        secondaryButtonOnClick: proceedNavigation(routeRestrictionDetails.secondaryButtonOnClick),
        primaryButtonOnClick: proceedRest(routeRestrictionDetails.primaryButtonOnClick),
      });
    }
  }, [blocker, routeRestrictionDetails]);

  const contextValue = useMemo<IAppRouteContextType>(
    () => ({
      routeRestrictionDetails,
      setRouteRestrictionDetails,
    }),
    [routeRestrictionDetails]
  );

  return <AppRouteContext.Provider value={contextValue}>{children}</AppRouteContext.Provider>;
};

export { AppRouteContextProvider, useAppRouteContext };
