import { Oval } from "react-loader-spinner";
import './Loader.scss'

const Loader = () => (
  <Oval
    visible={true}
    height="50"
    width="50"
    color="#095192"
    secondaryColor='white'
    ariaLabel="oval-loading"
    wrapperStyle={{}}
    wrapperClass="loader"
    strokeWidth='5'
  />
);

export default Loader;