import Chart from 'chart.js/auto'
import { useEffect, useRef } from 'react';

const Graph = (data: any) => {
  const chartRef = useRef<any>(null);
  const chartInstance = useRef<any>(null);

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }
    const myChartRef = chartRef?.current?.getContext('2d');
    chartInstance.current = new Chart(myChartRef, {
      type: "bar",
      data: data.data,
      options: {
        responsive: true,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        plugins: {
          legend: {
            position: 'bottom',
            labels : {
              boxWidth: 15,
              boxHeight: 15,
              font: {
                size: 8,
                weight: 700
              }
            }
          }
        },
        scales: {
          x : {
            display: true,
            ticks: {
              font: {
                size: 10,
                weight: 700
              }
            },
            grid: {
              display: false
            }          
          },
          left: {
            display: true,
            position: 'left',
            beginAtZero: true,
            title: {
              display: true,
              text: data?.axisLabel?.yAxisLeft,
              align: 'center',
              font: {
                size: 10,
                weight: 500,
              }
            },
            ticks: {
              count: 6,
              font: {
                size: 10,
                weight: 700
              },
              callback: function(value: any) {
                return data?.axisLabel?.yAxisLeft.includes('Rebate') ?  value + '%' :  '$' + value / 1000 + 'k';
              }
            },
            grid: {
              display: false
            }
          },
          right: {
            type: 'linear',
            display: !(data?.axisLabel?.yAxisLeft.includes('Rebate')),
            position: 'right',
            beginAtZero: true,
            title: {
              display: true,
              text: data?.axisLabel?.yAxisRight,
              align: 'center',
              font: {
                size: 10,
                weight: 500,
              }
            },
            ticks: {
              count: 6,
              font: {
                size: 10,
                weight: 700
              },
              callback: function(value: any) {
                return '$' + value / 1000 + 'k';
              }
            },
            grid: {
              display: false
            }
          },
        },
      }
    });
  }, []);


  return (
    <div data-testid='graph' className='flex align-items-center justify-content-center'>
      <canvas ref={chartRef} />
    </div>
  );
}

export default Graph;