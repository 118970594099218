import ConfigService from '../services/ConfigService';
import { STORAGE_KEYS } from '../constants/storageKeys';
import { ZodError, ZodType } from 'zod';
import { ServiceName } from '../enums/ServiceName';

/**
 * Construct full url using a base url
 * @param url string
 * @returns Full encoded cloud image url
 */
export const getUrlWithBaseUrl = (url: string): string => {
  const baseUrl = ConfigService.getEnvValue('REACT_APP_IMG_URL');
  return uriEncode(`${baseUrl}${url}`);
};

/**
 * Convert url to encoded format
 * @param url url to encode
 * @returns encoded url
 */
export const uriEncode = (url: string): string => {
  return encodeURI(url);
};

/**
 * Decode encoded url
 * @param encodedUrl url to decode
 * @returns decoded url
 */
export const uriDecode = (encodedUrl: string): string => {
  return decodeURI(encodedUrl);
};

/**
 * Validate image URL
 * @param imgUrl image url
 * @param callback callback function
 * @returns void
 */
export const validateImagUrl = (imgUrl: string, callback: (isValid: boolean) => void) => {
  const img = new Image();
  img.onload = () => callback(true);
  img.onerror = () => callback(false);
  img.src = imgUrl;
};

/**
 * The function `clearCookies` clears all cookies by setting their expiration date to a past time.
 */
export const clearCookies = () => {
  const cookies = document.cookie.split(';');
  cookies.forEach((cookie) => {
    document.cookie = cookie.split('=')[0] + '=;expires=Thu, 21 Sep 1979 00:00:01 UTC;';
  });
};

export const verifyHostName = (envHostName: string) => {
  return window.location.hostname === envHostName;
};

/**
 * Calculates the sum of a specific key from an array of items.
 *
 * @param {any[] | undefined} Items - The array of items to calculate the sum from.
 * @param {string} key - The key to use for summing up the values.
 * @return {number} The calculated sum based on the provided key.
 */
export const calculateTheSumBasedOnKey = (Items: any[] | undefined, key: string) => {
  return Items?.reduce((sum, item) => sum + (item[key] ?? 0), 0);
};

/* Get value from local Storage by the key */
export const getLocalStorageItem = (key: string) => localStorage.getItem(key);

/**
 * Sets the local storage with the key and the value
 * @param key
 * @param value
 * @returns
 */
export const setLocalStorageItem = (key: string, value: string) => localStorage.setItem(key, value);

export const deleteLocalStorageItem = (key: string) => localStorage.removeItem(key);

/* Get value from sessionStorage by the key */
export const getSessionStorageItem = (key: string) => sessionStorage.getItem(key);

/**
 * Gets the value from session storage by the key
 * @param key
 * @param value
 * @returns
 */
export const setSessionStorageItem = (key: string, value: string) =>
  sessionStorage.setItem(key, value);

/**
 * clears specific items like PracticeId, SiteId, StationId from local storage
 */
export const clearUserPracticeInfo = () => {
  const { SelectedPracticeId, SelectedSiteId, SelectedStationId } = STORAGE_KEYS;

  deleteLocalStorageItem(SelectedPracticeId);
  deleteLocalStorageItem(SelectedSiteId);
  deleteLocalStorageItem(SelectedStationId);
};

export const calculateNCRValue = (euPrice: number, reimbursementPrice: number, rebatePrice: number) => {
  return Math.round(reimbursementPrice + rebatePrice - euPrice);
}

export const calculateMultiplicationValue = (mgCat: number, price: number) => {
  return Math.round(mgCat * price);
}

export const calculatePercentage = (value: number, totalValue: number) => {
  return totalValue ? Math.round((value / totalValue) * 100) : 0;
}

export const calculateMgCat = (drugVolume: number, distributionVolume: number) => {
  return Math.round((drugVolume / 100) * distributionVolume);
}

export const deleteSessionStorageItem = (key: string) => sessionStorage.removeItem(key);

type FormValues = { [key: string]: any };

/**
 * Validate the form values based on the given schema, if 
 * any validation mismatch returns zod error against the field.
 * 
 * @param schema zod type schema for form validation
 * @param values 
 * @returns 
 */
export const validateWithSchema = <T extends FormValues>(
  schema: ZodType<T, any>,
  values: T
): any => {
  try {
    schema.parse(values);
  } catch (error) {
    if (error instanceof ZodError) {
      const fieldErrors: { [key: string]: string } = {};
      error.errors.forEach((err) => {
        err.path.forEach((field) => {
          fieldErrors[field] = err.message;
        });
      });
      return fieldErrors;
    }
    throw error;
  }
};

export const getScopesBasedOnService = (scopes: string[], serviceName: string) => {
  switch(serviceName) {
    case ServiceName.BFF_SERVICE:
      return [scopes[0]];
    case ServiceName.SM_SERVICE:
      return [scopes[1]];
  }
}
