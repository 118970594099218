import { Site } from './Site';

export class SiteAssignment {
  public id?: number;
  public userId?: number;
  public siteId?: number;
  public isActive?: boolean;
  public site?: Site;

  constructor(id?: number, userId?: number, siteId?: number, isActive?: boolean, site?: Site) {
    this.id = id;
    this.userId = userId;
    this.siteId = siteId;
    this.isActive = isActive;
    this.site = site;
  }
}
