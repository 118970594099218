import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Site } from '../models/Site';
import { SiteAssignment } from '../models/SiteAssignment';
import { sortData } from '../utils/sortUtils';
import { UserProfileContext } from './UserProfileContext';
import { STORAGE_KEYS } from '../constants/storageKeys';
import { getLocalStorageItem, setLocalStorageItem } from '../utils/utils';

const { SelectedPracticeId, SelectedSiteId, SelectedStationId } = STORAGE_KEYS;

interface SiteProviderProps {
  children: React.ReactNode;
}

export interface IAvailableSite {
  id: number;
  name: string;
}

export type SiteContextType =
  | {
      currentSite: Site;
      setCurrentSite: (currentSite: Site) => void;
      currentStationId: number;
      setCurrentStationId: (currentStationId: number) => void;
      availableSites: IAvailableSite[];
      setAvailableSites: Dispatch<SetStateAction<IAvailableSite[]>>;
    }
  | Record<string, never>;

const getDefaultSite = (siteAssignments: SiteAssignment[]): Site => {
  const selectedSiteId = getLocalStorageItem(SelectedSiteId);
  const selectSiteFromStorage = siteAssignments.find(
    (val) => val.siteId === Number(selectedSiteId)
  )?.site;
  return selectSiteFromStorage ?? siteAssignments[0]?.site ?? new Site(-1);
};

export const SiteContext = createContext<SiteContextType>({});

export const SiteProvider = ({ children }: SiteProviderProps) => {
  const [currentSite, setCurrentSite] = useState<Site>(new Site(-1));
  const [availableSites, setAvailableSites] = useState<IAvailableSite[]>([]);
  const [currentStationId, setCurrentStationId] = useState<number>(-1);
  const { user, availableStations } = useContext(UserProfileContext);

  useEffect(() => {
    if (currentSite.id && currentSite.id > 0) {
      setLocalStorageItem(SelectedSiteId, `${currentSite.id}`);
      setLocalStorageItem(SelectedStationId, `${currentStationId}`);
      setLocalStorageItem(SelectedPracticeId, `${currentSite.siteDetail?.practiceId ?? -1}`);
    }
  }, [currentSite, currentStationId]);

  useEffect(() => {
    const assignedSites = user?.getAssignedSites();
    const siteList = assignedSites
      ?.map((val) => {
        if (val?.id && val?.siteDetail) {
          return {
            id: val.id,
            name: val.siteDetail.name,
          };
        }
      })
      .filter(Boolean) as IAvailableSite[];
    setAvailableSites(sortData(siteList, 'name', 1));
  }, [user]);

  useEffect(() => {
    const siteAssignments = user?.siteAssignments ? user.siteAssignments : [];

    const defaultSite = getDefaultSite(siteAssignments);
    setCurrentSite(defaultSite);
    setCurrentStationId(getCurrentStationId);
  }, [user, availableStations]);

  const getCurrentStationId = () => {
    const selectedStationId = getLocalStorageItem(SelectedStationId);
    const stationId = availableStations?.includes(Number(selectedStationId))
      ? Number(selectedStationId)
      : -1;
    return stationId;
  };

  const contextValue = useMemo(
    () => ({
      currentSite,
      setCurrentSite,
      currentStationId,
      setCurrentStationId,
      availableSites,
      setAvailableSites,
    }),
    [
      currentSite,
      setCurrentSite,
      currentStationId,
      setCurrentStationId,
      availableSites,
      setAvailableSites,
    ]
  );

  return <SiteContext.Provider value={contextValue}>{children}</SiteContext.Provider>;
};
