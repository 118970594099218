import { useQuery } from 'react-query';
import PermissionsService from '../services/PermissionsService';
import { useMsal } from '@azure/msal-react';
import { QUERY_KEYS } from '../constants/queryKeys';

const useUserAssignments = (isEnabled: boolean) => {
  const { instance } = useMsal();
  const isLoggedIn = instance.getAllAccounts().length > 0;
  return useQuery({
    queryKey: QUERY_KEYS.getUserAssignments,
    queryFn: async () => {
      return await PermissionsService.getUserAssignments();
    },
    enabled: isLoggedIn && isEnabled,
  });
};

export default useUserAssignments;
