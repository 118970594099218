import './ScenarioOverviewGraph.scss'
import Graph from "./Graph";
import { useEffect, useState } from 'react';
import DataNotFound from '../data-not-found/DataNotFound';

export interface FinalData {
  heading: string;
  scenarioTotalPrice?: string | number;
  [key: string]: number | string | undefined;
}

interface GraphFields {
  label: string;
  data: string[];
  yAxisID: string;
  type: string
}

export interface IScenarioOverviewGraph {
  finalData: FinalData[][]
}

interface GraphData {
  labels: string[],
  datasets: (GraphFields | object)[]
}

const ScenarioOverviewGraph = ({ finalData }: IScenarioOverviewGraph) => {
  const [data, setData] = useState<(GraphData | object)[]>([]);
  const graphHeading = ["EU Volume by product", "Reimbursement per EU", "Net Cost Recovery per EU", "Quarterly Reimbursements", "Quarterly Net cost recovery", "Rebate Estimate"];
  const axisLabel = [
    {
      yAxisLeft: "EU Volume (Drug Level)",
      yAxisRight: "EU Volume (Scenario Total)"
    },
    {
      yAxisLeft: "Reimbursement (Drug Level)",
      yAxisRight: "Reimbursement (Scenario Total)"
    },
    {
      yAxisLeft: "NCR Per EU (Drug Level)",
      yAxisRight: "NCR Per EU (Scenario Total)"
    },
    {
      yAxisLeft: "Annualized Reimbursements (Drug Level)",
      yAxisRight: "Annualized Reimbursements (Scenario Total)"
    },
    {
      yAxisLeft: "Annualized NCR (Drug Level)",
      yAxisRight: "Annualized NCR (Scenario Total)"
    },
    {
      yAxisLeft: "Rebate Estimate (Drug Level)"
    },
  ];

  const calculateGraphData = (drugNames: string[], labels: string[]) =>
    finalData?.map((item: FinalData[], index: number) => {
      if (!item?.length) return {};

      const datasets = drugNames
        .map((drugName) => {
          const drugValues = item.map((scenario) => scenario[drugName]);

          return index === 5 && drugName === 'scenarioTotalPrice'
            ? {}
            : {
              label: drugName === 'scenarioTotalPrice' ? 'TOTAL PRICE' : drugName,
              data: drugValues,
              yAxisID: drugName === 'scenarioTotalPrice' ? 'right' : 'left',
              type: drugName === 'scenarioTotalPrice' ? 'line' : 'bar',
            };
        })
        .filter((dataset) => Object.keys(dataset).length);

      return { labels, datasets };
    });

  useEffect(() => {
    const data = finalData[0]?.length ? finalData[0] : finalData[3];
    const lables = data?.map((item: FinalData) => {
      return item.heading
    });
    const drugNames = data[0] && Object.keys(data[0]).filter(heading => heading !== 'heading' && heading !== 'mainHeader');
    const graphData = calculateGraphData(drugNames, lables);
    setData(graphData);
  }, [finalData]);

  return (
    <div className="graph-box grid">
      {
        graphHeading.map((heading, index) => {
          return (
            <div key={`${heading} ${index}`} className='col-6'>
              <div className="graph-container">
                <div className="graph-heading-box">
                  <div data-testid='graph-heading' className="graph-heading-text">
                    {graphHeading[index]}
                  </div>
                </div>
                {
                  data[index] && Object.keys(data[index])?.length ?
                    <Graph data={data[index]} axisLabel={axisLabel[index]} />
                    :
                    <DataNotFound text={'Data Not Found'} className='data-not-found flex align-items-center justify-content-center opacity-70' />
                }
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default ScenarioOverviewGraph