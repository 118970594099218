import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import ConfigService from './ConfigService';

const reactPlugin = new ReactPlugin();
const config = ConfigService.getConfig();
const appInsightsPromise = config.then((data) => {
  const connectionString = data.applicationInsights.connectionString;

  const appInsights = new ApplicationInsights({
    config: {
      connectionString: connectionString,
      extensions: [reactPlugin],
      extensionConfig: {},
      enableAutoRouteTracking: true,
      disableAjaxTracking: false,
      autoTrackPageVisitTime: true,
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
    },
  });

  appInsights.loadAppInsights();

  return appInsights;
});

export { reactPlugin, appInsightsPromise };
