import { useQuery } from 'react-query';
import ConfigService from '../services/ConfigService';

const useConfig = () => {
  const response = useQuery(['config'], () => {
    const data = ConfigService.getConfig();
    return data.then((config) => {
      return config;
    });
  });
  const { data, isSuccess } = response;

  return { data, isSuccess };
};

export default useConfig;
