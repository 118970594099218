interface IDataNotFound {
  text: string
  className?: string
}

const DataNotFound = ({text, className}: IDataNotFound) => {
  return (
    <div className={className}>{text}</div>
  )
};

export default DataNotFound;