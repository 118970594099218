export interface IPracticeDetails {
  id: number;
  name: string;
  practiceId: number;
  displayName: string | null;
  accountNumber: string;
  isPracticeActive: boolean;
  corpEntityId?: string | null;
}
export class PracticeAssignment {
  public id?: number;
  public userId?: number;
  public practiceId?: number;
  public isActive?: boolean;
  public practiceDetails?: IPracticeDetails[];

  constructor(id?: number, userId?: number, practiceId?: number, isActive?: boolean, practiceDetails?: IPracticeDetails[]) {
    this.id = id;
    this.userId = userId;
    this.practiceId = practiceId;
    this.isActive = isActive;
    this.practiceDetails = practiceDetails;
  }
}

export interface IUserSite {
  siteId: number;
  siteName: string;
  displayName: string;
  name: string;
  isActive: boolean;
  practiceId: number;
  practiceName: string;
  // specialtyType: SpecialtyType;
  // site: Site;
}

export interface IUserPractice {
  practiceId: number;
  practiceName: string;
  isActive: boolean;
  practiceDetail: IPracticeDetails;
  practiceSites?: IUserSite[] | [];
}
