import { useContext, useEffect, useRef, useState } from 'react';
import Header from '../../components/header/Header'
import './Home.scss'
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { Messages } from '../../enums/Messages';
import { Dropdown } from 'primereact/dropdown';
import { PrimaryButton } from '../../components/global/PrimaryButton';
import Loader from '../../components/loader/Loader';
import { calculateTheSumBasedOnKey, validateWithSchema } from '../../utils/utils';
import { Field, Form, Formik, FormikValues } from 'formik';
import { formValidation } from '../../constants/formValidation';
import { CategoryActionType, useCategoryContext } from '../../contexts/ScenarioModelCategoryContext';
import { useGetAllCategoriesList } from '../../hooks/useGetAllCategoriesList';
import { ButtonLabels } from '../../enums/ButtonLabels';
import { SecondaryButton } from '../../components/global/SecondaryButton';
import DefinitionTable from '../../components/definition-table/DefinitionTable';
import { ScenarioHeading } from '../../enums/ScenarioHeading';
import { UserProfileContext } from '../../contexts/UserProfileContext';
import { IUserPractice } from '../../models/PracticeAssignment';
import { IPracticeOptionTemplateProps } from '../../models/UserSite';

export interface ProductFamily {
  productFamilyId: number,
  productFamilyName: string,
  loggedInUserDistribution: number | null | [number, number],
  isLocked?: boolean,
}
export interface Category {
  category: string,
  productFamilyDetails: ProductFamily[]
}

export interface InitialValue {
  selectedUserPractice: string,
  selectedCategory: string,
}

export interface FinancialDrug {
  drugName: string,
  mgCat: number,
  price: number,
  reimbursementPrice: number,
  rebateValue: number,
  volume?: number | null | [number, number],
  isLocked?: boolean
}
export interface Scenario {
  heading: string,
  value: ProductFamily[]
}

const Home = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [userPractices, setUserPractices] = useState<IUserPractice[]>([]);
  const [selectedUserPractice, setSelectedUserPractice] = useState<IUserPractice>();
  const [selectedCategory, setSelectedCategory] = useState<Category>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [scenarios, setScenarios] = useState<Scenario[]>([]);
  const endOfListRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { state, dispatch } = useCategoryContext();
  const { user } = useContext(UserProfileContext);
  const { selectedCategory: storedCategory } = state;
  const initialValues = {
    selectedUserPractice: state?.selectedUserPractice?.practiceName ?? '',
    selectedCategory: storedCategory?.category ?? '',
  }

  const { data: financialCategories, isError: isCategoriesError, isLoading: isCategoriesLoading } = useGetAllCategoriesList(selectedUserPractice?.practiceId);

  useEffect(() => {
    if (state?.selectedUserPractice)
      setSelectedUserPractice(state?.selectedUserPractice);
    if (state?.selectedCategory)
      setSelectedCategory(state?.selectedCategory);
    if (state?.scenarios?.length)
      setScenarios(state?.scenarios);
  }, [state]);

  useEffect(() => {
    if (endOfListRef?.current) {
      endOfListRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scenarios.length]);

  useEffect(() => {
    if (isCategoriesError) {
      onGetAllSelectedFinancialDrugsError();
    }

    if(isCategoriesLoading) {
      setIsLoading(true);
    }
  }, [isCategoriesError, isCategoriesLoading]);

  useEffect(() => {
    if (financialCategories?.length) {
      setIsLoading(false);
      setCategories(financialCategories);
    }
  }, [financialCategories]);

  useEffect(() => {
    setUserPractices(user?.userPractices ?? []);
    user?.userPractices && setIsLoading(false);
  }, [user]);

  const onGetAllSelectedFinancialDrugsError = () => {
    setIsLoading(false);
    toast.error(Messages.SOMETHING_WENT_WRONG, {
      duration: 2000
    });
  };

  const onPracticeChange = (practice: IPracticeOptionTemplateProps, formikValue: InitialValue) => {
    setSelectedUserPractice(practice);
    setSelectedCategory(undefined);
    formikValue.selectedUserPractice = practice?.practiceName;
  }

  const handleSelectedCategory = (value: string, formikValue: InitialValue) => {
    const index = categories.findIndex((category: Category) => category?.category === value);
    const newCategory = categories[index];
    setSelectedCategory(newCategory);
    formikValue.selectedCategory = newCategory.category;
    const newProductFamilies = newCategory?.productFamilyDetails?.map((productFamily: ProductFamily) => ({
      ...productFamily,
      isLocked: true
    }));
    setScenarios([
      {
        heading: ScenarioHeading.CURRENT,
        value: newProductFamilies,
      }
    ]);
  }

  const handleAddScenario = () => {
    setScenarios((previous) => [...previous, { heading: `${ScenarioHeading.SCENARIO} ${scenarios.length}`, value: scenarios[0]?.value?.map((productFamily: ProductFamily) => { return { ...productFamily, isLocked: false } }) }]);
  }

  const handleGenerateScenario = () => {
    const newArray: number[] = [];
    scenarios.forEach((item, index) => {
      if (item.heading === ScenarioHeading.CURRENT) return;
      const total = calculateTheSumBasedOnKey(item?.value, "loggedInUserDistribution")

      if (total !== 100) {
        newArray.push(index);
      }
    });
    if (newArray.length === 0) {
      const selectedValues = {
        selectedUserPractice : selectedUserPractice,
        selectedCategory : selectedCategory,
        scenarios : scenarios
      }
      dispatch({type: CategoryActionType.DEFINE_SCENARIO, payload: selectedValues});      
      navigate('/scenarioOverview');
    } else {
      const errorMessage = `${Messages.VOLUME_DISTRIBUTION_EXCEEDS}${newArray.map((item: number, index: number) => {
        return newArray.length - 1 === index && newArray.length > 1 ? ` and ${ScenarioHeading.SCENARIO} ${item}.` : ` ${ScenarioHeading.SCENARIO} ${item}`;
      })}`;
      toast.error(errorMessage, {
        duration: 4000,
        style: {
          width: "20%"
        }
      });
    }
  }

  return (
    <div className='main-content'>
      <Header title='Scenario Modeling' />
      <Toaster position='top-right' />
      <Formik
        initialValues={initialValues}
        onSubmit={handleGenerateScenario}
        validate={(values) => validateWithSchema(formValidation, values)}
        validateOnChange={true}
      >
        {
          ({ values, errors }: FormikValues) => {
            return (
              <Form>
                <div className='home mt-3 ml-4  w-10'>
                  <div className='category-card border-round-md px-3 py-2 flex flex-column gap-3'>
                    <div className='flex flex-column gap-2 pt-1 category w-8'>
                      <label>{"Select Practice"}</label>
                      <Field name='selectedPractice'>
                        {
                          () => {
                            return (
                              <Dropdown
                                data-testid='practiceDropDown'
                                value={selectedUserPractice}
                                onChange={(e) => onPracticeChange(e.value, values)}
                                options={userPractices}
                                optionLabel='practiceName'
                                placeholder='Select a Practice'
                                className='p-inputtext-sm'
                                dataKey='practiceName'
                              />
                            )
                          }
                        }
                      </Field>
                      {!values?.selectedUserPractice && <div className='text-xs text-red-700'>{errors?.selectedUserPractice}</div>}
                    </div>
                    <div className='flex flex-column gap-2 pt-1 category w-8'>
                      <label>{"Select Category"}</label>
                      <Field name='selectedCategory'>
                        {
                          () => {
                            return (
                              <Dropdown
                                data-testid='categoryDropDown'
                                value={selectedCategory}
                                onChange={(e) => handleSelectedCategory(e.value.category, values)}
                                options={categories}
                                optionLabel='category'
                                placeholder='Select a Category'
                                className='p-inputtext-sm'
                                dataKey='category'
                              />
                            )
                          }
                        }
                      </Field>
                      {!values?.selectedCategory && <div className='text-xs text-red-700'>{errors?.selectedCategory}</div>}
                    </div>
                    <div className="table-container">
                      {
                        selectedCategory?.category && scenarios?.map((item: Scenario, number: number) => {
                          return (
                            <div className='table-card' key={`${item.heading} ${number}`}>
                              <div data-testid='scenario-definition-table' ref={endOfListRef} />
                              <DefinitionTable
                                index={number}
                                scenarios={scenarios}
                                setScenario={setScenarios}
                              />
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                  <div className='flex align-items-center justify-content-end gap-3 mb-2'>
                    <SecondaryButton label={ButtonLabels.ADD_SCENARIO} icon="pi pi-plus" className="text-xs py-2" onClick={handleAddScenario} />
                    <PrimaryButton type='submit' dataTestId="generate-scenario" label={ButtonLabels.GENERATE_SCENARIO} icon="pi pi-angle-right" iconPos="right" className="text-xs px-3" />
                  </div>
                </div>
              </Form>
            )
          }}
      </Formik>
      {
        isLoading && <Loader />
      }
    </div>
  )
}

export default Home;
