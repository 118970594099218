import { useMsal } from '@azure/msal-react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { sessionTimeoutConfig } from '../../auth/authConfig';
import { appInsightsPromise } from '../../services/ApplicationInsightsService';
import '../../styles/components/Buttons.scss';
import '../../styles/components/LogoutModal.scss';
import { AppInsightsEvents } from './../../enums/AppInsightsEvents';
import { PrimaryButton } from './PrimaryButton';
import useConfig from '../../hooks/useConfig';
import { clearCookies } from '../../utils/utils';

export const LogoutModal = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [remaining, setRemaining] = useState<number>(sessionTimeoutConfig.promptBeforeIdle);
  const { instance } = useMsal();
  const { data: configData } = useConfig();

  /**
   * Clear auth tokens from the session and redirect back to the login page.
   */
  const handleLogout = () => {
    const win: Window = window;
    const accounts = instance.getAllAccounts();

    // Track user logout event in app insights
    appInsightsPromise
      .then((appInsights) => {
        const additionalProperties = {
          Name: accounts[0].name,
          'User ID': accounts[0].username,
        };
        appInsights.trackEvent({ name: AppInsightsEvents.UserLogoutEvent }, additionalProperties);
      })
      .catch((error) => console.log('Error loading app insights config'))
      .finally(() => {
        clearCookies();
        sessionStorage.clear();
        localStorage.clear();
        win.location = `${configData?.auth.logoutUrl}&id_token_hint=${accounts[0].idToken}`;
        setVisible(false);
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  /** When the user is idle */
  const onIdle = () => {
    handleLogout();
  };

  /** When the user becomes active again */
  const onActive = () => {
    setVisible(false);
  };

  /** Modal gets triggered */
  const onPrompt = () => {
    setVisible(true);
  };

  const onAction = (event?: Event) => {
    if (event?.type === 'mousedown') {
      // Activate triggers the onActive function
      activate();
    }
  };

  const renderTimeRemaining = () => {
    const minutes = Math.floor(remaining / 60);
    const extraSeconds = remaining % 60;
    const displayMinutes = minutes < 10 ? '0' + minutes : minutes;
    const displaySeconds = extraSeconds < 10 ? '0' + extraSeconds : extraSeconds;
    return `${displayMinutes}:${displaySeconds}`;
  };

  const footer = () => {
    return (
      <div className='text-center'>
        <Button
          onClick={handleLogout}
          className='uppercase p-button-outlined border-2 border-round-lg font-bold mr-2 outlined-button'
          label='Log Out'
          outlined
        />
        <PrimaryButton onClick={() => activate()} className='ml-2 mr-0' label='Keep Working' />
      </div>
    );
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    onPrompt,
    timeout: sessionTimeoutConfig.timeout,
    promptBeforeIdle: sessionTimeoutConfig.promptBeforeIdle,
    throttle: 500,
  });

  return (
    <Dialog
      header='Session will expire soon!'
      visible={visible}
      modal={true}
      closable={false}
      position='center'
      dismissableMask={true}
      draggable={false}
      headerClassName='text-center border-round-top-md'
      focusOnShow={false}
      className='logout-modal'
      maskClassName='logout-modal'
      onHide={() => setVisible(false)}
      breakpoints={{ '960px': '75vw', '641px': '100vw' }}
      footer={footer}
    >
      <div className='logout-modal text-center'>
        <h6 className='font-normal mt-1'>
          You will be automatically logged out for security reasons in
        </h6>
        <div className='m-0'>
          <h1>{renderTimeRemaining()}</h1>
        </div>
        <h6 className='font-normal mb-0 pb-4'>Would you like to continue working?</h6>
      </div>
    </Dialog>
  );
};
