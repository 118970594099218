import { Toolbar as ReactToolbar } from 'primereact/toolbar';
import React, { useState } from 'react';
import '../../styles/components/Toolbar.scss';
import ConfirmationModal from '../global/ConfirmationModal';
import { appInsightsPromise } from '../../services/ApplicationInsightsService';
import { AppInsightsEvents } from '../../enums/AppInsightsEvents';
import { useMsal } from '@azure/msal-react';
import { Messages } from '../../enums/Messages';
import { Button } from 'primereact/button';
import 'primeflex/primeflex.css';
import { Modal } from './Modal';
import useConfig from '../../hooks/useConfig';
import { clearCookies } from '../../utils/utils';

const Toolbar: React.FC = () => {
  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);
  const [isLoadingModalVisible, setIsLoadingModalVisible] = useState(false);
  const { instance } = useMsal();
  const { data: configData } = useConfig();

  const noContinue = () => {
    setIsLogoutModalVisible(false);
  };

  const handleLogout = () => {
    setIsLogoutModalVisible(false);
    setIsLoadingModalVisible(true);
    setTimeout(() => {
      const win: Window = window;
      const accounts = instance.getAllAccounts();

      // Track user logout event in app insights
      appInsightsPromise
        .then((appInsights) => {
          const additionalProperties = {
            Name: accounts[0].name,
            'User ID': accounts[0].username,
          };
          appInsights.trackEvent({ name: AppInsightsEvents.UserLogoutEvent }, additionalProperties);
        })
        .catch((error) => console.log('Error loading app insights config'))
        .finally(() => {
          clearCookies();
          sessionStorage.clear();
          localStorage.clear();
          win.location = `${configData?.auth.logoutUrl}&id_token_hint=${accounts[0].idToken}`;
        });
    }, 2000);
  };

  const loadingModalContent = () => {
    return (
      <div className='flex justify-content-center align-items-center gap-4 pt-3'>
        <span className='global-modal-header-text font-bold'>{'Logging Out'}</span>
        <i className={'flex global-modal-header-text pi pi-spin pi-spinner'} />
      </div>
    );
  };
  const rightContents = (
    <Button
      icon='pi pi-sign-out'
      className='text-primary logout-button'
      rounded
      text
      tooltip='Logout'
      tooltipOptions={{ position: 'left' }}
      id='btn-logout'
      aria-label='logout'
      onClick={() => setIsLogoutModalVisible(true)}
    />
  );

  return (
    <>
      <div className='toolbar-container'>
        <ReactToolbar end={rightContents} />
      </div>
      <ConfirmationModal
        isVisible={isLogoutModalVisible}
        setIsVisible={setIsLogoutModalVisible}
        isIcon={false}
        primaryButtonLabel='YES, LOG OUT'
        primaryButtonOnClick={handleLogout}
        secondaryButtonLabel='NO, CONTINUE WORKING'
        secondaryButtonOnClick={noContinue}
        headerText={''}
        bodyContent={<h5 className='font-bold m-0'>{Messages.LOGOUT_CONFIRM_HEADER}</h5>}
        bodyText={''}
        className={'logout-confirm-modal'}
      />

      <Modal
        visible={isLoadingModalVisible}
        setVisible={setIsLoadingModalVisible}
        isClosable={false}
        bodyContent={loadingModalContent()}
        headerText=''
        className='logout-spinner-modal'
      />
    </>
  );
};

export default Toolbar;
