import { Button } from 'primereact/button';

export interface PrimaryButtonProps {
  label?: string;
  size?: string;
  className?: string;
  icon?: string;
  onClick?: () => void;
  disabled?: boolean;
  iconPos?: 'left' | 'right';
  dataTestId?: string;
  type?: "submit" | "reset" | "button";
}

/** Customized Primary Button - solid filled */
export const PrimaryButton = ({
  label,
  size,
  className,
  onClick,
  icon,
  disabled = false,
  iconPos,
  dataTestId,
  type
}: PrimaryButtonProps) => {
  return (
    <Button
      rounded
      onClick={onClick}
      label={label}
      icon={icon ?? icon}
      iconPos={iconPos ?? 'left'}
      size='small'
      className={`uppercase border-2 ${size ?? ''} font-bold primary-button ${className}`}
      disabled={disabled}
      data-testid={dataTestId}
      type={type ?? 'button'}
    />
  );
};
