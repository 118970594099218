import Header from "../../components/header/Header";
import './ScenarioOverview.scss';
import ScenarioOverviewGraph from "../../components/graph/ScenarioOverviewGraph";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import ScenarioOverviewTable from "../../components/scenario-overview-table/ScenarioOverviewTable";
import { SecondaryButton } from "../../components/global/SecondaryButton";
import { SelectButton, SelectButtonChangeEvent } from 'primereact/selectbutton';
import { calculateMgCat, calculateMultiplicationValue, calculateNCRValue, calculatePercentage, calculateTheSumBasedOnKey, deleteSessionStorageItem } from "../../utils/utils";
import { ScenarioOverviewFinancialDrug, Scenario } from "../../interfaces/IScenarioModeling";
import { handleExportGraphPDF, handleExportTablePDF } from "../../utils/filesExportUtils";
import Loader from "../../components/loader/Loader";
import { useScenarioContext } from "../../contexts/ScenarioModelScenarioContext";
import { CategoryActionType, useCategoryContext } from "../../contexts/ScenarioModelCategoryContext";
import { ButtonLabels } from "../../enums/ButtonLabels";

interface JustifyOption {
  icon: string;
  value: string;
}

interface CalculatedFinancialDrug {
  heading: string;
  value: ScenarioOverviewFinancialDrug[];
  totalScenarioPrice?: number;
  totalReimbursementPrice: number;
  totalNCRValue: number;
}

export interface ScenarioOverviewFinalData {
  heading: string;
  scenarioTotalPrice?: string | number;
  [key: string]: number | string | undefined;
}

export interface ScenarioOverviewTableData extends ScenarioOverviewFinalData {
  REPORT_TYPE: string
}

const ScenarioOverview = () => {
  const [scenarioOverviewCalculatedData, setScenarioOverviewCalculatedData] = useState<ScenarioOverviewFinalData[][]>([]);
  const [isGraphView, setIsGraphView] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const reportTemplateRef = useRef(null);
  const { state } = useScenarioContext();
  const { dispatch: categoryDispatch } = useCategoryContext();
  const { sixMonthScenarios: data, quarterlyScenarios: quarterlyData } = state;
  const navigate = useNavigate();
  const justifyOptions: JustifyOption[] = [
    { icon: 'pi pi-table', value: 'table' },
    { icon: 'pi pi-chart-line', value: 'graph' },
  ];
  const [value, setValue] = useState<string>(justifyOptions[1].value);
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 200) {
        setIsHeaderFixed(true);
      } else {
        setIsHeaderFixed(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleClick = (e: string | null) => {
    setValue(e ?? value);
    setIsGraphView(e ? !isGraphView : isGraphView);
  };

  const handleReset = () => {
    categoryDispatch({ type: CategoryActionType.DEFINE_SCENARIO });
    deleteSessionStorageItem('scenarios');
    navigate('/');
  }

  const handleModifyDrugs = () => {
    deleteSessionStorageItem('scenarios');
    navigate('/');
  }

  const handleModifyScenario = () => {
    navigate('/');
  }

  const exportPDF = () => {
    setIsLoading(true);
    const promise = isGraphView ? handleExportGraphPDF(reportTemplateRef) : handleExportTablePDF(reportTemplateRef);
    promise.then(() => {
      setIsLoading(false);
    });
  };

  /**
   * Calculate new mgCat for each drug based on the volume distributed
   * for the respected drugs. Using the new mgCat calculate the reimbursementPrice
   * and ncrValue for each drug.
   *  
   * @param value array of scenario type object
   * @returns updated data with calculated reimbursementPrice and ncrValue.
   */
  const calculateQuarterlyData = (value: Scenario[]) => {
    const quarterlyUpdatedData = value?.map((item: Scenario) => {

      if (item.heading !== 'Current') {
        const distributionVolume = calculateTheSumBasedOnKey(item?.value, 'mgCat');
        item?.value?.forEach((drug: ScenarioOverviewFinancialDrug) => {
          const newMgCat = calculateMgCat(drug.volume, distributionVolume);
          drug.mgCat = newMgCat;
        })
      }

      const newDrug = item?.value?.map((drug: ScenarioOverviewFinancialDrug) => ({
        ...drug,
        reimbursementPrice: calculateMultiplicationValue(drug.mgCat, drug.reimbursementPrice),
        ncrValue: calculateNCRValue(calculateMultiplicationValue(drug.mgCat, drug.price), calculateMultiplicationValue(drug.mgCat, drug.reimbursementPrice), calculateMultiplicationValue(drug.mgCat, drug.rebateValue)),
      }));

      const totalReimbursementPrice = calculateTheSumBasedOnKey(newDrug, 'reimbursementPrice');
      const totalNCRValue = calculateTheSumBasedOnKey(newDrug, 'ncrValue');
      return {
        ...item,
        value: newDrug,
        totalReimbursementPrice: totalReimbursementPrice,
        totalNCRValue: totalNCRValue
      }
    });

    return quarterlyUpdatedData;
  };

  /**
   * Calculate new mgCat for each drug based on the volume distributed
   * for the respected drugs. Using the new mgCat calculate the totalEUPrice,
   * reimbursementPrice, rebateValue and ncrValue for each drug.
   *  
   * @param value array of scenario type object
   * @returns updated data with calculated totalEUPrice, reimbursementPrice, rebateValue and ncrValue.
   */
  const calculateSixMonthData = (value: Scenario[]) => {
    const updatedData = value?.map((item: Scenario) => {

      if (item.heading !== 'Current') {
        const distributionVolume = calculateTheSumBasedOnKey(item.value, 'mgCat');
        item?.value?.forEach((drug: ScenarioOverviewFinancialDrug) => {
          const newMgCat = calculateMgCat(drug.volume, distributionVolume);
          drug.mgCat = newMgCat;
        })
      }
      const newDrug = item?.value?.map((drug: ScenarioOverviewFinancialDrug) => ({
        ...drug,
        totalEUPrice: calculateMultiplicationValue(drug.mgCat, drug.price),
        reimbursementPrice: calculateMultiplicationValue(drug.mgCat, drug.reimbursementPrice),
        ncrValue: calculateNCRValue(calculateMultiplicationValue(drug.mgCat, drug.price), calculateMultiplicationValue(drug.mgCat, drug.reimbursementPrice), calculateMultiplicationValue(drug.mgCat, drug.rebateValue)),
        rebateValue: calculateMultiplicationValue(drug.mgCat, drug.rebateValue),
      }));
      const totalPrice = calculateTheSumBasedOnKey(newDrug, 'totalEUPrice');
      const totalReimbursementPrice = calculateTheSumBasedOnKey(newDrug, 'reimbursementPrice');
      const totalNCRValue = calculateTheSumBasedOnKey(newDrug, 'ncrValue');
      const totalRebateValue = calculateTheSumBasedOnKey(newDrug, 'rebateValue');
      const updatedDrug = newDrug?.map((drug) => ({
        ...drug,
        rebatePercentage: calculatePercentage(drug.rebateValue, totalRebateValue),
      }));

      return {
        ...item,
        value: updatedDrug,
        totalScenarioPrice: totalPrice,
        totalReimbursementPrice: totalReimbursementPrice,
        totalNCRValue: totalNCRValue,
      };
    }
    );
    return updatedData;
  }

  useEffect(() => {
    const newEUVolumeData: ScenarioOverviewFinalData[] = [];
    const newReimbursementData: ScenarioOverviewFinalData[] = [];
    const newNetCostRecoveryData: ScenarioOverviewFinalData[] = [];
    const newQuarterlyReimbursementData: ScenarioOverviewFinalData[] = [];
    const newQuarterlyNCRData: ScenarioOverviewFinalData[] = [];
    const newRebateData: ScenarioOverviewFinalData[] = [];

    if (quarterlyData[0]?.value?.length !== 0) {
      const quarterlyUpdatedData = calculateQuarterlyData(quarterlyData);

      quarterlyUpdatedData.forEach((item: CalculatedFinancialDrug) => {
        const transformedQuarterlyReimbursementData: ScenarioOverviewFinalData = {
          heading: item?.heading,
          scenarioTotalPrice: item.totalReimbursementPrice,
        }

        const transformedQuarterlyNCRData: ScenarioOverviewFinalData = {
          heading: item?.heading,
          scenarioTotalPrice: item.totalNCRValue,
        }

        item?.value?.forEach((drug: ScenarioOverviewFinancialDrug) => {
          transformedQuarterlyReimbursementData[drug.drugName] = drug.reimbursementPrice;
          transformedQuarterlyNCRData[drug.drugName] = drug.ncrValue;
        });

        newQuarterlyReimbursementData.push(transformedQuarterlyReimbursementData);
        newQuarterlyNCRData.push(transformedQuarterlyNCRData);
      });
    }

    if (data[0]?.value?.length !== 0) {
      const updatedData = calculateSixMonthData(data);

      updatedData.forEach((item: CalculatedFinancialDrug, index: number) => {
        const transformedEUVolumeData: ScenarioOverviewFinalData = {
          heading: item?.heading,
          scenarioTotalPrice: item.totalScenarioPrice,
        };
        const transformedReimbursementData: ScenarioOverviewFinalData = {
          heading: item?.heading,
          scenarioTotalPrice: item.totalReimbursementPrice,
        };
        const transformedNetCostRecoveryData: ScenarioOverviewFinalData = {
          heading: item?.heading,
          scenarioTotalPrice: item.totalNCRValue,
        };
        const transformedRebateData: ScenarioOverviewFinalData = {
          heading: item?.heading,
        };

        item?.value?.forEach((drug: ScenarioOverviewFinancialDrug) => {
          transformedEUVolumeData[drug.drugName] = drug?.totalEUPrice;
          transformedReimbursementData[drug.drugName] = drug?.reimbursementPrice;
          transformedNetCostRecoveryData[drug.drugName] = drug?.ncrValue;
          transformedRebateData[drug.drugName] = drug?.rebatePercentage;
        });
        newEUVolumeData.push(transformedEUVolumeData);
        newReimbursementData.push(transformedReimbursementData);
        newNetCostRecoveryData.push(transformedNetCostRecoveryData);
        newRebateData.push(transformedRebateData);
      });
    }
    const finalScenarioData = [newEUVolumeData, newReimbursementData, newNetCostRecoveryData, newQuarterlyReimbursementData, newQuarterlyNCRData, newRebateData];
    setScenarioOverviewCalculatedData(finalScenarioData);
  }, [state]);

  const justifyTemplate = (option: JustifyOption) => {
    return <i data-testid={`${option.value}-view`} className={option.icon} style={{ fontSize: '1.05rem' }}></i>;
  }

  return (
    <div className="main-content">
      <Header title="Scenario Modeling" />
      <div className="scenario-overview">
        <div className="scenario-overview-card flex align-items-center justify-content-between" style={{
          position: isHeaderFixed ? 'fixed' : 'absolute',
          top: isHeaderFixed ? `50px` : 'auto',
        }}>
          <div className="text-lg font-bold">{"Scenarios overview"}</div>
          <div className="scenario-overview-subcard flex align-items-center justify-content-between" >
            <SecondaryButton label={ButtonLabels.RESET} className="text-sm reset-button" onClick={handleReset} />
            <SelectButton className="selectbutton" value={value} onChange={(e: SelectButtonChangeEvent) => handleClick(e.value)} itemTemplate={justifyTemplate} optionLabel="value" options={justifyOptions} />
            <SecondaryButton label={ButtonLabels.MODIFY_DRUGS} icon="pi pi-list" onClick={handleModifyDrugs} className="text-sm" />
            <SecondaryButton label={ButtonLabels.EDIT_SCENARIOS} icon="pi pi-sliders-h" onClick={handleModifyScenario} className="text-sm" />
            <SecondaryButton label={ButtonLabels.EXPORT} icon="pi pi-upload" className="text-sm" onClick={exportPDF} />
          </div>
        </div>
        {
          isGraphView &&
            scenarioOverviewCalculatedData.length ?
            <div ref={reportTemplateRef}>
              <ScenarioOverviewGraph finalData={scenarioOverviewCalculatedData} />
            </div> :
            <ScenarioOverviewTable data={scenarioOverviewCalculatedData} drugs={data[0]?.value?.length ? data[0] : quarterlyData[0]} tableRef={reportTemplateRef} />
        }
      </div>
      {
        isLoading && <Loader />
      }
    </div>
  )
}

export default ScenarioOverview;