import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../auth/authConfig';
import '../styles/pages/HomePage.scss';
import { useEffect } from 'react';

const HomePage: React.FC = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log('Error logging in: ', e);
    });
  };

  useEffect(() => {
    handleLogin();
  }, []);

  return <></>;
};
export default HomePage;
