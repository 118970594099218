import { createContext, Dispatch, ReactNode, useContext, useEffect, useMemo, useReducer } from "react";
import { Scenario } from "../interfaces/IScenarioModeling";
import { getSessionStorageItem, setSessionStorageItem } from "../utils/utils";

export enum ScenarioActionType {
  DEFINE_SCENARIOS = 'DEFINE_SCENARIOS',
  SET_INITIAL_STATE = 'SET_INITIAL_STATE'
}

export interface IInitialScenarioState {
  scenarios: Scenario[],
  sixMonthScenarios: Scenario[],
  quarterlyScenarios: Scenario[]
}

const initialState: IInitialScenarioState = {
  scenarios: [],
  sixMonthScenarios: [],
  quarterlyScenarios: []
}

export interface IScenarioContextType {
  state: IInitialScenarioState,
  dispatch: Dispatch<IScenarioAction>
}

export interface IScenarioAction {
  type: ScenarioActionType,
  payload?: any
}

export const scenarioReducer = (
  state: IInitialScenarioState,
  action: IScenarioAction
): IInitialScenarioState => {
  switch(action.type) {
    case ScenarioActionType.DEFINE_SCENARIOS: {
      const scenarios = {...state, scenarios: action?.payload?.scenarios, sixMonthScenarios: action?.payload?.sixMonthScenarios, quarterlyScenarios: action?.payload?.quarterlyScenarios};
      setSessionStorageItem('scenarios', JSON.stringify(scenarios));
      return scenarios
    }
    case ScenarioActionType.SET_INITIAL_STATE:
      return action.payload
  }
};

interface IScenarioProviderProps {
  children: ReactNode
}

const ScenarioContext = createContext<{state: IInitialScenarioState, dispatch: Dispatch<IScenarioAction>} | undefined>(undefined);

const useScenarioContext = () => {
  const context = useContext(ScenarioContext);

  if (!context) {
    throw new Error('ScenarioContext must be used within a ScenarioContextProvider');
  }
  return context;
}

const ScenarioContextProvider = ({children}: IScenarioProviderProps) => {
  const [state, dispatch] = useReducer(scenarioReducer, initialState);

  useEffect(() => {
    const newState = getSessionStorageItem('scenarios');

    if (newState) {
      const updatedState = JSON.parse(newState);
      dispatch({type: ScenarioActionType.SET_INITIAL_STATE, payload: updatedState});
    }
  }, []);

  const contextValue = useMemo<IScenarioContextType>(
    () => ({
      state,
      dispatch
    }), [state]
  )
  return <ScenarioContext.Provider value={contextValue}>{children}</ScenarioContext.Provider>;
};

export {ScenarioContextProvider, useScenarioContext, ScenarioContext}