const getConfig = async () => {
  try {
    const config = await fetch('/config.json');
    const response = await config.json();

    return response;
  } catch (error) {
    console.log('An error occurred reading application config file: ', error);
    throw error;
  }
};
const getEnvValue = (name: string): string => {
  return process.env[name] ?? '';
};

const ConfigService = {
  getConfig,
  getEnvValue,
};

export default ConfigService;
