export class SiteDetail {
  public id: number;
  public name!: string;
  public accountNumber!: string;
  public billToAddress1!: string;
  public billToAddress2!: string;
  public billToCity!: string;
  public billToState!: string;
  public billToZip!: string;
  public physicalAddress1!: string;
  public physicalAddress2!: string;
  public physicalCity!: string;
  public physicalState!: string;
  public physicalZip!: string;
  public DEA!: string;
  public medicalLicense!: string;
  public NPI!: number;
  public phoneNumber!: string;
  public contactName!: string;
  public contactEmail!: string;
  public endDate!: Date;
  public isSiteActive!: boolean;
  public createdDate!: Date;
  public createdBy!: string;
  public updatedDate!: Date;
  public updatedBy!: string;
  public practiceId!: number;
  public siteId!: number;

  constructor(
    id: number,
    name: string,
    accountNumber: string,
    billToAddress1: string,
    billToAddress2: string,
    billToCity: string,
    billToState: string,
    billToZip: string,
    physicalAddress1: string,
    physicalAddress2: string,
    physicalCity: string,
    physicalState: string,
    physicalZip: string,
    DEA: string,
    medicalLicense: string,
    NPI: number,
    phoneNumber: string,
    contactName: string,
    contactEmail: string,
    endDate: Date,
    isSiteActive: boolean,
    createdDate: Date,
    createdBy: string,
    updatedDate: Date,
    updatedBy: string,
    practiceId: number,
    siteId: number
  ) {
    this.id = id;
    this.name = name;
    this.accountNumber = accountNumber;
    this.billToAddress1 = billToAddress1;
    this.billToAddress2 = billToAddress2;
    this.billToCity = billToCity;
    this.billToState = billToState;
    this.billToZip = billToZip;
    this.physicalAddress1 = physicalAddress1;
    this.physicalAddress2 = physicalAddress2;
    this.physicalCity = physicalCity;
    this.physicalState = physicalState;
    this.physicalZip = physicalZip;
    this.DEA = DEA;
    this.medicalLicense = medicalLicense;
    this.NPI = NPI;
    this.phoneNumber = phoneNumber;
    this.contactName = contactName;
    this.contactEmail = contactEmail;
    this.endDate = endDate;
    this.isSiteActive = isSiteActive;
    this.createdDate = createdDate;
    this.createdBy = createdBy;
    this.updatedDate = updatedDate;
    this.updatedBy = updatedBy;
    this.practiceId = practiceId;
    this.siteId = siteId;
  }
}
