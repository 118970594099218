import { IPracticeDetails, IUserPractice, PracticeAssignment } from './PracticeAssignment';
import { SiteAssignment } from './SiteAssignment';

export class User {
  public id?: number;
  public adoId?: string;
  public corporateEntityId?: number;
  public isActive?: boolean;
  public contactEmail?: string;
  public firstName?: string;
  public lastName?: string;
  public phoneNumber?: string;
  public practiceAssignments?: PracticeAssignment[];
  public siteAssignments?: SiteAssignment[];
  public userRoles?: UserRole[];
  public userPractices?: IUserPractice[];

  constructor(
    id?: number,
    adoId?: string,
    corporateEntityId?: number,
    isActive?: boolean,
    contactEmail?: string,
    firstName?: string,
    lastName?: string,
    phoneNumber?: string,
    practiceAssignments?: PracticeAssignment[],
    siteAssignments?: SiteAssignment[],
    userRoles?: UserRole[],
  ) {
    this.id = id;
    this.adoId = adoId;
    this.corporateEntityId = corporateEntityId;
    this.isActive = isActive;
    this.contactEmail = contactEmail;
    this.firstName = firstName;
    this.lastName = lastName;
    this.phoneNumber = phoneNumber;
    this.practiceAssignments = practiceAssignments;
    this.siteAssignments = siteAssignments;
    this.userRoles = userRoles;
    this.userPractices =
      practiceAssignments &&
      siteAssignments &&
      getUserRelatedPractices(practiceAssignments, siteAssignments);
  }

  public getDisplayName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  public getAssignedSites() {
    const sites = this.siteAssignments?.flatMap((assignment) => {
      if (assignment.site !== null) {
        return [assignment.site];
      } else {
        return [];
      }
    });

    return sites ?? [];
  }

  public isAllyOnlyRole(): boolean {
    return !!(this.userRoles?.[0]?.role?.isAllyOnly ?? false);
  }
}

export class UserRole {
  public role: Role;
  constructor(role: Role) {
    this.role = role;
  }
}
export class Role {
  public isAllyOnly: boolean;
  public name: string;
  constructor(isAllOnly: boolean, name: string) {
    this.isAllyOnly = isAllOnly;
    this.name = name;
  }
}

const getUserRelatedPractices = (
  practiceAssignments: PracticeAssignment[],
  siteAssignments: SiteAssignment[]
): IUserPractice[] => {
  return practiceAssignments
    .map((pa) => {
      const practice: IUserPractice = {
        practiceId: pa?.practiceId ?? 0,
        practiceName:
          (pa?.practiceDetails &&
            (pa?.practiceDetails[0]?.displayName ?? pa?.practiceDetails[0]?.name)) ??
          '',
        isActive: pa?.isActive ?? true,
        practiceDetail: (pa?.practiceDetails?.[0]) ?? ({} as IPracticeDetails),
      };
      return practice;
    })
    .filter((practice) => practice !== undefined)
    .sort((a, b) => a.practiceName.localeCompare(b.practiceName));
};
