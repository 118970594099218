import './Header.scss';

export interface IHeader {
  title: string
}

const Header = ({
  title  
 }: IHeader) => {

  return (
    <div className="flex align-items-center scenario-header">
      {title}
    </div>
  )

}

export default Header
