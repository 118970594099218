import { PracticeAssignment } from '../models/PracticeAssignment';
import { Site } from '../models/Site';
import { SiteAssignment } from '../models/SiteAssignment';
import { SiteDetail } from '../models/SiteDetail';
import { SpecialtyType } from '../models/SpecialtyType';
import { User } from '../models/User';
import { apiClientAppBff } from './ApiClient';

const getUserAssignments = async () => {
  try {
    const { data } = await apiClientAppBff.get('/permissions/assignments', {
      transformRequest: [
        (data, headers) => {
          // Remove the 'x-user-context' header if it exists
          if (headers['x-user-context']) {
            delete headers['x-user-context'];
          }
          return data;
        },
      ],
    });

    const practiceAssignments =
      data?.practiceAssignments
        ? data.practiceAssignments.map((pa: any) => {
            const practiceAssignment = new PracticeAssignment(
              pa.id,
              pa.userId,
              pa.practiceId,
              pa.isActive,
              pa.practiceDetails
            );
            return practiceAssignment;
          })
        : [];

    const siteAssignments =
      data?.siteAssignments
        ? data.siteAssignments.map((sa: any) => {
            const siteDetail =
              sa.site.siteDetails.length > 0
                ? new SiteDetail(
                    sa.site.siteDetails[0].id,
                    sa.site.siteDetails[0].name,
                    sa.site.siteDetails[0].accountNumber,
                    sa.site.siteDetails[0].billToAddress1,
                    sa.site.siteDetails[0].billToAddress2,
                    sa.site.siteDetails[0].billToCity,
                    sa.site.siteDetails[0].billToState,
                    sa.site.siteDetails[0].billToZip,
                    sa.site.siteDetails[0].physicalAddress1,
                    sa.site.siteDetails[0].physicalAddress2,
                    sa.site.siteDetails[0].physicalCity,
                    sa.site.siteDetails[0].physicalState,
                    sa.site.siteDetails[0].physicalZip,
                    sa.site.siteDetails[0].DEA,
                    sa.site.siteDetails[0].medicalLicense,
                    sa.site.siteDetails[0].NPI,
                    sa.site.siteDetails[0].phoneNumber,
                    sa.site.siteDetails[0].contactName,
                    sa.site.siteDetails[0].contactEmail,
                    sa.site.siteDetails[0].endDate,
                    sa.site.siteDetails[0].isSiteActive,
                    sa.site.siteDetails[0].createdDate,
                    sa.site.siteDetails[0].createdBy,
                    sa.site.siteDetails[0].updatedDate,
                    sa.site.siteDetails[0].updatedBy,
                    sa.site.siteDetails[0].practiceID,
                    sa.site.siteDetails[0].siteId
                  )
                : undefined;

            const site = new Site(
              sa.site.id,
              sa.site.isShippingSite,
              new SpecialtyType(
                sa.specialtyType?.id,
                sa.specialtyType?.name,
                sa.specialtyType?.isActive
              ),
              siteDetail
            );
            const siteAssignment = new SiteAssignment(
              sa.id,
              sa.userId,
              sa.siteId,
              sa.isActive,
              site
            );
            return siteAssignment;
          })
        : [];

    const user = new User(
      data.id,
      data.adoid,
      data.corporateEntityId,
      data.isActive,
      data.contactEmail,
      data.firstName,
      data.lastName,
      data.phoneNumber,
      practiceAssignments,
      siteAssignments,
      data.usersRoles
    );

    return user;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const PermissionsService = {
  getUserAssignments,
};

export default PermissionsService;
