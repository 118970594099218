import { forwardRef, useImperativeHandle, useState } from 'react';
import ConfirmationModal, { IConfirmationModalProps } from './ConfirmationModal';
import { ButtonLabels } from '../../enums/ButtonLabels';
import { Messages } from '../../enums/Messages';

export interface IGlobalConfirmationModal {
  showGlobalConfirmationModal: (props: IConfirmationModalProps) => void;
}

export const defaultGlobalConfirmationModalValues = {
  isVisible: false,
  setIsVisible: () => null,
  secondaryButtonLabel: ButtonLabels.YES_IAM_SURE,
  primaryButtonLabel: ButtonLabels.NO_CONTINUE,
  secondaryButtonOnClick: () => null,
  primaryButtonOnClick: () => null,
  headerText: Messages.CANCEL_CONFIRMATION,
  bodyText: Messages.CANCEL_CONFIRMATION_BODY,
};

const GlobalConfirmationModal = forwardRef<IGlobalConfirmationModal>((props, ref) => {
  const [modalDetails, setModalDetails] = useState<IConfirmationModalProps>(
    defaultGlobalConfirmationModalValues
  );

  useImperativeHandle(ref, () => ({
    showGlobalConfirmationModal(value: IConfirmationModalProps) {
      setModalDetails({ ...value });
    },
  }));

  return <ConfirmationModal {...modalDetails} />;
});

GlobalConfirmationModal.displayName = 'GlobalConfirmationModal';
export default GlobalConfirmationModal;
