export const scopes = [];

export const msalConfig = {
  auth: {
    clientId: '',
    authority: '',
    knownAuthorities: {} as any,
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  redirectStartPage: '/',
  scopes: scopes,
};

export const logoutRequest = {
  postLogoutRedirectUri: '/',
  scopes,
};

export const sessionTimeoutConfig = {
  timeout: 600_000, // 10 mins
  promptBeforeIdle: 60_000, // 1 min
};
