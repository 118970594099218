import { Button } from 'primereact/button';
import '../../styles/components/Buttons.scss';
export interface SecondaryButtonProps {
  label?: string;
  size?: 'small' | 'large';
  className?: string;
  icon?: string;
  onClick?: () => void;
  isDisabled?: boolean;
  type?: "submit" | "reset" | "button";
}

/** Customized Primary Button - solid filled */
export const SecondaryButton = ({
  label,
  size,
  className,
  onClick,
  icon,
  isDisabled = false,
  type
}: SecondaryButtonProps) => {
  return (
    <Button
      outlined
      onClick={onClick}
      label={label}
      icon={icon ?? icon}
      size={size}
      iconPos={icon ? 'left' : undefined}
      className={` outlined-button ${className}`}
      disabled={isDisabled}
      type={type ?? 'button'}
    />
  );
};
