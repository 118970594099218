export const sortData = <T>(
  data: T[],
  sortField: keyof T | undefined,
  sortOrder: 1 | 0 | -1 | undefined,
  customComparator?: (a: T, b: T) => number
): T[] => {
  //Return empty set if there's no data
  if (!data || data.length === 0) {
    return [];
  }

  //if there's no sort field or sort order, return the data as is
  if (!sortField || sortOrder === undefined) {
    return [...data];
  }

  //sort the data with the given customComparator function if it exists
  if (customComparator) {
    return data.slice().sort(customComparator);
  }

  //else sort the data with the given sortField and sortOrder
  //1 is ascending, -1 is descending
  return data.slice().sort((a: T, b: T) => {
    const valueA = a[sortField];
    const valueB = b[sortField];

    if (valueA === valueB) {
      return 0;
    }

    if (sortOrder === 1) {
      return valueA > valueB ? 1 : -1;
    } else {
      return valueA < valueB ? 1 : -1;
    }
  });
};
