import { Dialog, DialogProps } from 'primereact/dialog';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import '../../styles/common/Modal.scss';
import '../../styles/components/Buttons.scss';
import '../../styles/pages/OrdersShoppingCartPage.scss';

export interface ModalProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  footer?: ReactNode | ((props: DialogProps) => ReactNode); // Prime React Dialog footer props
  headerText: string;
  icon?: string; // Example: 'pi pi-exclamation-circle'
  bodyText?: string;
  bodyContent?: any;
  className?: string;
  isClosable?: boolean;
  bodyClassName?: string;
  bodyTextClassName?: string;
}

export const Modal = ({
  visible,
  setVisible,
  footer,
  headerText,
  icon,
  bodyText,
  bodyContent,
  className,
  isClosable = true,
  bodyClassName,
  bodyTextClassName = 'global-modal-body-text flex justify-content-center align-items-center',
}: ModalProps) => {
  const renderIcon = () => {
    return icon ? (
      <span>
        <i className={`${icon} global-modal-icon mr-3`}></i>
      </span>
    ) : null;
  };

  const headerContainer = () => {
    return <div className='global-modal-header-text font-bold text-left'>{headerText ?? ''}</div>;
  };

  return (
    <Dialog
      visible={visible}
      modal={true}
      closable={isClosable}
      resizable={false}
      position='center'
      dismissableMask={true}
      draggable={false}
      headerClassName='text-center border-round-top-md pb-0'
      focusOnShow={false}
      header={() => headerContainer()}
      className={className ?? 'global-modal'}
      onHide={() => setVisible(false)}
      breakpoints={{ '960px': '75vw', '641px': '100vw' }}
      footer={footer}
    >
      <div>
        {renderIcon() || bodyText ? (
          <div className={bodyTextClassName}>
            {renderIcon()}
            <span className='font-medium'>{bodyText ?? ''}</span>
          </div>
        ) : (
          <></>
        )}
        {/* Dropdowns and other components */}
        <div className={bodyClassName ?? 'global-modal-body-content'}>{bodyContent ?? null}</div>
      </div>
    </Dialog>
  );
};
